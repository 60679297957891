<template>
  <breadcrumb-header
    v-if="showBreadcrumbs"
    :items="breadcrumbs"
  />
  <v-tabs v-model="tab">
    <smpw-tab
      value="details"
      :active="tab === 'details'"
      :text="t('location.info')"
    />
    <smpw-tab
      v-if="location?.images?.length"
      value="images"
      :active="tab === 'images'"
      :text="t('images.gallery')"
    />
    <smpw-tab
      value="store_info"
      :active="tab === 'store_info'"
      :text="t('store.info')"
    />
    <smpw-tab
      v-if="shiftId"
      value="store_contacts"
      :active="tab === 'store_contacts'"
      :text="t('store.contact', 2)"
    />
  </v-tabs>
  <v-window
    v-if="location"
    v-model="tab"
    :touch="false"
  >
    <v-window-item value="details">
      <div
        class="tiptap text-font"
        v-html="location.details"
      />
      <location-marker-editor
        v-model="location"
        class="mt-2"
        :store="location?.store"
        read-only
        @change:tab="onTabChange"
      />
    </v-window-item>
    <v-window-item value="images">
      <image-gallery
        v-model="location.images"
        name="location_gallery"
        enable-slide-show
        read-only
      />
    </v-window-item>
    <v-window-item value="store_info">
      <show-store
        v-if="location.store_id"
        :id="location.store_id"
        :with-breadcrumbs="false"
      />
    </v-window-item>
    <v-window-item
      v-if="shiftId"
      value="store_contacts"
    >
      <v-alert
        v-if="!storeContacts || (storeContacts.length === 0)"
        type="info"
        :text="t('store.no_contacts_available')"
      />
      <v-row>
        <v-col
          v-for="(captainLocation, index) in storeContacts"
          :key="index"
          cols="12"
          sm="6"
          md="4"
        >
          <member-card
            :user="captainLocation.captain"
            :title="captainLocation.location.name"
            :show-email="false"
            fill-height
          />
        </v-col>
      </v-row>
    </v-window-item>
  </v-window>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors.ts';
import axios from 'axios';

import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ImageGallery from '../images/ImageGallery.vue';
import ShowStore from '../stores/ShowStore.vue';
import MemberCard from '../teams/MemberCard.vue';
import LocationMarkerEditor from './LocationMarkerEditor.vue';

const props = withDefaults(defineProps<{
  locationId: number | string;
  shiftId?: number;
  showBreadcrumbs?: boolean;
}>(), {
  showBreadcrumbs: () => true,
});

const { t } = useI18n();

const { handleError } = useErrorStore();

const tab = ref('details');
const location = ref<App.Models.Location>();

function onTabChange(e) {
  tab.value = e;
}

const storeContacts = ref<any>([]);
async function loadData() {
  try {
    const locationResponse = await axios.get(`/api/locations/${props.locationId}`, {
      params: {
        inc: ['images', 'markers', 'markers.images', 'heroImage', 'store', 'store.images'],
      },
    });
    location.value = locationResponse.data.data;
    if (props.shiftId) {
      const shiftResponse = await axios.get(`/api/users/shifts/${props.shiftId}/stores`);
      storeContacts.value = shiftResponse.data.data;
    }
  }
  catch (error) {
    handleError(error, t('errors.cannot_load'));
  }
}

onMounted(() => {
  loadData();
});

const breadcrumbs = computed(() => [
  {
    title: t('location.title'),
    to: '/locations',
  },
  {
    title: location.value?.name ?? t('states.loading'),
  },
]);
</script>
