<template>
  <div>
    <breadcrumb-header
      :items="breadcrumbs"
      help-link="/docs/publisher/managing-shifts/locations"
    />
    <v-tabs
      v-model="tab"
    >
      <smpw-tab
        value="locations"
        :active="tab === 'locations'"
        :text="t('location.title', 2)"
      />
      <smpw-tab
        value="stores"
        :active="tab === 'stores'"
        :text="t('store.title', 2)"
      />
      <smpw-tab
        value="map"
        :active="tab === 'map'"
        :text="t('location.global_map')"
      />
    </v-tabs>
    <v-window
      v-model="tab"
      :touch="false"
    >
      <v-window-item value="locations">
        <div
          data-testid="locations-filter"
          class="d-flex flex-xs-column mb-3"
        >
          <v-icon
            color="grey-darken-1"
            icon="fas fa-filter"
            class="me-2 ms-2"
            size="x-small"
          />
          <div>
            <v-btn
              v-for="day in days"
              :key="day.value"
              class="me-1 mb-1"
              :class="{ day_badge_inactive: !day.active }"
              :data-testid="`locations-day-filter-${day.value}`"
              :text="day.name"
              @click="day.active = !day.active; $event.target.blur();"
            />
          </div>
        </div>
        <v-row
          v-if="locations.length > 0"
        >
          <v-col
            v-for="location in locations"
            :key="location.id"
            :md="4"
            :cols="12"
          >
            <v-card
              class="fill-height mx-1"
            >
              <v-img
                :src="getHeroImage(location)"
                :alt="location.name"
                max-height="200px"
                class="align-end"
                cover
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              />
              <v-card-title class="text-primary-darken-1">
                {{ location.name }}
              </v-card-title>

              <v-card-text>
                <v-chip
                  v-for="day in days"
                  :key="day.value"
                  size="x-small"
                  class="ms-1 mb-1"
                  :text="day.name"
                  :disabled="!location.weekdays?.includes(day.value)"
                />
                <v-btn
                  :to="getLocationDetailsUrl(location.id)"
                  color="primary"
                  :text="t('location.view_details')"
                  block
                  size="large"
                  class="mb-2 mt-1"
                />
                <v-btn
                  :disabled="!location?.store?.active"
                  :to="getStoreDetailsUrl(location?.store?.id)"
                  color="primary"
                  variant="outlined"
                  :elevation="2"
                  size="large"
                  block
                  :text="`${t('store.view')} - ${location?.store?.name}`"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-alert
          v-else
          color="primary"
          :text="t('location.none_found')"
        />
      </v-window-item>
      <v-window-item value="stores">
        <v-row
          v-if="tempStores.length > 0"
        >
          <v-col
            v-for="store in tempStores"
            :key="store.id"
            :md="4"
            :cols="12"
          >
            <v-card
              class="my-4"
              :data-testid="`store-${store.id}`"
            >
              <v-img
                max-height="200px"
                class="align-end"
                cover
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                src="/storage/images/defaut_store_hero.jpg"
                :alt="store.name"
              />
              <v-card-title>
                {{ store.name }}
              </v-card-title>
              <v-card-text>
                <v-btn
                  :to="getStoreDetailsUrl(store.id)"
                  color="primary"
                  :text="t('store.view_details')"
                  block
                  size="large"
                  class="mb-4"
                />
                <v-divider class="mb-2" />
                <v-card-subtitle>
                  {{ t('store.locations_using') }}
                </v-card-subtitle>
                <v-list>
                  <v-list-item
                    v-for="location in store.locations"
                    :key="location.id"
                    :to="getLocationDetailsUrl(location.id)"
                    :disabled="!location.active"
                  >
                    {{ location.name }}
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-alert
          v-else
          color="primary"
          :text="t('store.none_found')"
        />
      </v-window-item>
      <v-window-item value="map">
        <global-locations-map />
      </v-window-item>
    </v-window>
  </div>
</template>

<script setup lang="ts">
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import GlobalLocationsMap from './GlobalLocationsMap.vue';

const { t } = useI18n();
const tab = ref('locations');
const { showSnackMessage } = useErrorStore();
const tempLocations = ref<App.Models.Location[]>([]);
const tempStores = ref<App.Models.Store[]>([]);

const breadcrumbs = computed(() => [{ title: t('location.title', 2) }]);

const days = ref([
  {
    name: t('date.monday'),
    value: 1,
    active: true,
  },
  {
    name: t('date.tuesday'),
    value: 2,
    active: true,
  },
  {
    name: t('date.wednesday'),
    value: 3,
    active: true,
  },
  {
    name: t('date.thursday'),
    value: 4,
    active: true,
  },
  {
    name: t('date.friday'),
    value: 5,
    active: true,
  },
  {
    name: t('date.saturday'),
    value: 6,
    active: true,
  },
  {
    name: t('date.sunday'),
    value: 7,
    active: true,
  },
]);

const activeDays = computed(() => days.value.filter((day) => (day.active === true)).map((day) => day.value));

function getLocationDetailsUrl(id): string {
  return `/locations/${id}`;
}
function getStoreDetailsUrl(id): string {
  return `/stores/${id}`;
}
function getHeroImage(location): string {
  return location?.hero_image?.signed_file_url ?? '/storage/images/defaut_location_hero.jpg';
}

const locations = computed(() => tempLocations.value.filter(
  (item) => item?.weekdays?.filter((value) => activeDays?.value?.includes(value)).length > 0
    || item?.weekdays?.length === 0,
));

async function fetchLocations() {
  try {
    const res = await axios.get('/api/locations', { params: { active: true, inc: ['weekdays', 'heroImage', 'store', 'store.heroImage'], sort: 'name' } });
    tempLocations.value = res.data.data;
  }
  catch (error) {
    console.error(error);
    tempLocations.value = [];
    showSnackMessage(t('location.cannot_load'));
  }
}
async function fetchStores() {
  try {
    const res = await axios.get('/api/stores', { params: { active: true, inc: ['weekdays', 'heroImage', 'locations'], sort: 'name' } });
    tempStores.value = res.data.data;
  }
  catch (error) {
    console.error(error);
    tempStores.value = [];
    showSnackMessage(t('store.cannot_load'));
  }
}

fetchLocations();
fetchStores();
</script>

<style scoped>
.day_badge_inactive {
    opacity: 0.5;
}
</style>
